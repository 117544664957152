<template>
  <div class="invoicing-detail">
    <div class="facilityBox">
      <div class="settleBox">
        <el-row>
          <el-col :span="5"
            ><strong>发票抬头：</strong>{{ settlementInfo.TitleName }}</el-col
          >
          <el-col :span="5"
            ><strong>纳税人识别号：</strong>{{ settlementInfo.TitleCreditCode }}</el-col
          >
          <el-col :span="5"
            ><strong>开户银行：</strong>{{ settlementInfo.TitleBankerName }}</el-col
          >
          <el-col :span="9"
            ><strong>开户银行账号：</strong>{{ settlementInfo.TitleBankerNumber }}</el-col
          >
        </el-row>
        <el-row style="margin: 14px 0px">
          <el-col :span="5"
            ><strong>运单条数：</strong>{{ settlementInfo.BillCount }}</el-col
          >
          <el-col :span="5"
            ><strong>运费(元)：</strong>
            <span style="color: #ff8b17; font-weight: bold">{{
              settlementInfo.BaseAmount | formatMoney
            }}</span></el-col
          >
          <el-col :span="5"
            ><strong>服务费/税费(元)：</strong
            ><span style="color: #ff8b17; font-weight: bold">{{
              settlementInfo.ExpensesOfTaxation | formatMoney
            }}</span></el-col
          >
          <el-col :span="9"
            ><strong>运单总金额(元)：</strong
            ><span style="color: #ff8b17; font-weight: bold">{{
              settlementInfo.TotalAmount | formatMoney
            }}</span>
          </el-col>
        </el-row>
        <el-row style="margin: 14px 0px">
          <el-col :span="5"><strong>费率：</strong>{{ settlementInfo.PRateStr }}</el-col>
          <el-col :span="5"
            ><strong>支付方式：</strong>{{ settlementInfo.PayModeName }}</el-col
          >
          <el-col :span="5"
            ><strong>联系电话：</strong>{{ settlementInfo.TitlePhone }}</el-col
          >
          <el-col :span="9"
            ><strong>单位地址：</strong>{{ settlementInfo.TitleAdress }}</el-col
          >
        </el-row>
        <el-row>
          <el-col :span="5"
            ><strong>发票类型：</strong>{{ settlementInfo.InvoiceKindName }}</el-col
          >
          <el-col :span="5"
            ><strong>业务类型：</strong>{{ settlementInfo.BusinessTypeName }}</el-col
          >
          <el-col :span="5"
            ><strong>场景类型：</strong>{{ settlementInfo.ScenarioTypeName }}</el-col
          >
          <el-col :span="9"
            ><strong>开票类型：</strong>{{ settlementInfo.InvoiceTypeName }}</el-col
          >
        </el-row>
      </div>
      <div style="display: flex; align-items: center; margin-bottom: 8px">
        <div>
          <span>已签约服务商：</span>
          <el-select v-model="settlementInfo.SupplierName" disabled style="width: 260px">
          </el-select>
        </div>
        <div style="margin: 0px 108px">
          <span>专票普票：</span>
          <el-select v-model="settlementInfo.InvoiceKindName" disabled> </el-select>
        </div>
        <div>
          <span>支付方式：</span>
          <el-select v-model="settlementInfo.PayModeName" disabled style="width: 300px">
          </el-select>
        </div>
      </div>
      <el-table
        :data="currentTableData"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="loading"
        key="tableData"
      >
        <el-table-column
          type="index"
          label="序号"
          width="50"
          align="center"
          fixed="left"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="BSID" label="开票申请编号" width="200" align="center">
          <template slot-scope="scope">
            <el-link type="primary" @click="invoiceDetail(scope.row)">{{
              scope.row.BSID
            }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="BPID" label="发票任务号" width="200" align="center">
        </el-table-column>
        <el-table-column
          prop="TaxAmount"
          label="服务费/税费"
          width="180"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{ scope.row.TaxAmount | formatMoney }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="TotalAmount"
          label="运单总金额"
          width="180"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{ scope.row.TotalAmount | formatMoney }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="BillCount"
          label="运单数量"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="InvoiceNumber"
          label="发票号"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="CreateTime"
          label="开票时间"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="FeeType"
          label="开票类型"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{
            scope.row.FeeType == 0 ? "运费" : "技术服务费"
          }}</template>
        </el-table-column>
        <el-table-column
          prop="StatusName"
          label="当前进度"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.Status != 1 && scope.row.Status != 2"
              >{{ scope.row.StatusName }}
            </el-tag>
            <el-tag type="info" v-if="scope.row.Status == 1">{{
              scope.row.StatusName
            }}</el-tag>
            <el-tag type="danger" v-if="scope.row.Status == 2">{{
              scope.row.StatusName
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="getUploadInvoiceList(scope.row)"
              :loading="flag.invoicePreviewLoading"
              style="color: #0f5fff"
              v-if="scope.row.InvoiceNumber"
              >查看发票
            </el-button>
            <!-- <el-button type="text" @click="toRejectedInvoice(scope.row)" style="color: #FF2323;"
                            v-if="scope.row.Status ==80">驳回重开
                        </el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div style="margin: 10px 0 0 0">
        <!-- 分页 -->
        <el-pagination
          class="pagination"
          background
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.page"
          :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
      <div style="width: 100%; text-align: center">
        <el-button type="info" plain size="medium" icon="el-icon-back" @click="goBack"
          >返回
        </el-button>
        <el-button
          type="danger"
          size="medium"
          icon="el-icon-close"
          @click="flag.rejectedInvoiceDialog = true"
          v-if="status == 80"
          >驳回重开</el-button
        >
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-s-claim"
          @click="flag.confirmInvoiceDialog = true"
          v-if="status == 80"
          >确认收票</el-button
        >
        <el-button
          type="primary"
          plain
          size="medium"
          icon="el-icon-download"
          @click="downloadInvoiceAll"
        >
          下载全部发票
        </el-button>
      </div>
    </div>
    <!-- 确认收票弹窗 -->
    <el-dialog
      :visible.sync="flag.confirmInvoiceDialog"
      v-if="flag.confirmInvoiceDialog"
      width="380px"
      :close-on-click-modal="false"
    >
      <div slot="title" style="display: flex; align-items: center">
        <i class="el-icon-success" style="color: #00e855; font-size: 24px"></i>
        <span style="font-size: 16px; font-weight: bold; margin-left: 4px"
          >确认收票提示</span
        >
      </div>
      <div style="margin-bottom: 8px; font-size: 14px; font-weight: bold">
        请你确认收票已全部下载！
      </div>
      <div style="font-size: 14px">
        若你在此界面未下载发票，确认收票后可在完成开票界面下载发票
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button plain type="info" @click="flag.confirmInvoiceDialog = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="confirmInvoice">确认收票</el-button>
      </span>
    </el-dialog>
    <!-- 驳回重开弹窗 -->
    <el-dialog
      :visible.sync="flag.rejectedInvoiceDialog"
      v-if="flag.rejectedInvoiceDialog"
      width="380px"
      :close-on-click-modal="false"
    >
      <div slot="title" style="display: flex; align-items: center">
        <i class="el-icon-warning" style="color: #ff8b17; font-size: 24px"></i>
        <span style="font-size: 16px; font-weight: bold; margin-left: 4px"
          >重新开票提示</span
        >
      </div>
      <div style="margin-bottom: 8px; font-size: 14px; font-weight: bold">
        你确定发票有误，需要服务商重新开票吗？
      </div>
      <div style="font-size: 14px">每个发票任务单号只能进行一次重开</div>
      <span slot="footer" class="dialog-footer">
        <el-button plain type="info" @click="flag.rejectedInvoiceDialog = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="rejectedInvoice">重新开票</el-button>
      </span>
    </el-dialog>
    <!-- 运单详情 -->
    <el-dialog
      width="1500px"
      title="查看详情"
      :visible.sync="flag.invoiceDetailDialog"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      v-if="flag.invoiceDetailDialog"
    >
      <el-table
        :data="detailData"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="loading"
        key="detailData"
      >
        <el-table-column
          type="index"
          label="序号"
          width="50"
          align="center"
          fixed="left"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="BBID"
          label="运单号"
          width="200"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="ApplicantName"
          label="货主单位"
          width="180"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="SupplierName"
          label="服务商单位"
          width="180"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="InvoiceTypeName"
          label="发票类型"
          align="center"
          show-overflow-tooltip
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="Shipper"
          label="托运人"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ShipownerName"
          label="承运人"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ShipownerIDCard"
          label="承运人身份证号"
          show-overflow-tooltip
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="ShipownerPhone"
          label="承运人手机号"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="PayeeName"
          label="代收人"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="PayeeIDCard"
          label="代收人身份证号"
          show-overflow-tooltip
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="PayeePhone"
          label="代收人手机号"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ShipName"
          label="船舶名称"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="GoodsName"
          label="货物名称"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="GoodsUnitName"
          label="货物单位"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="OriginAddress"
          label="起始地"
          show-overflow-tooltip
          align="center"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="ArriveAddress"
          label="到达地"
          show-overflow-tooltip
          align="center"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="OriginTime"
          label="起始时间"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ArriveTime"
          label="到达时间"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="Counts"
          label="货物数量"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="Price"
          label="运输单价"
          show-overflow-tooltip
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{ scope.row.Price | formatMoney }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="CarriageFee"
          label="运费"
          show-overflow-tooltip
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{ scope.row.CarriageFee | formatMoney }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="ExpensesOfTaxation"
          label="服务费/税费"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="!settlementInfo.TaskTypeID.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{
              scope.row.ExpensesOfTaxation | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="TotalPrice"
          label="运单总金额"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="!settlementInfo.TaskTypeID.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{ scope.row.TotalPrice | formatMoney }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="TrustFee"
          label="委托代开运费"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="settlementInfo.TaskTypeID.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{ scope.row.TrustFee | formatMoney }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="ExpensesOfTaxation"
          label="委托代开服务费/税费"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="settlementInfo.TaskTypeID.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{
              scope.row.ExpensesOfTaxation | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="TotalPrice"
          label="委托代开运单总金额"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="settlementInfo.TaskTypeID.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{ scope.row.TotalPrice | formatMoney }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="ThirdBillID"
          label="客户单号"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
      </el-table>
      <div style="margin: 10px 0 0 0">
        <!-- 分页 -->
        <el-pagination
          class="pagination"
          background
          @current-change="handleCurrentDetailChange"
          :current-page.sync="paginationDetail.page"
          :page-size="paginationDetail.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="paginationDetail.total"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <!-- 发票包预览 -->
    <el-dialog
      width="1200px"
      title="查看发票"
      :visible.sync="flag.invoicePreviewDialog"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div>
        <el-image
          style="width: 1100px; height: 500px"
          :src="invoicePics[currentPicIdx]"
          :preview-src-list="[invoicePics[currentPicIdx]]"
        >
        <div slot="error" class="image-slot imageError">
            <span>已由系统回填信息</span>
          </div>
        </el-image>
      </div>
      <div style="text-align: right; margin-top: 16px">
        <el-button
          type="info"
          plain
          size="medium"
          @click="flag.invoicePreviewDialog = false"
          >关闭
        </el-button>
        <el-button type="primary" plain size="medium" @click="toPrevious"
          >上一张
        </el-button>
        <el-button type="primary" plain size="medium" @click="toNext">下一张 </el-button>
        <el-button type="primary" size="medium" @click="downloadInvoiceAll"
          >下载全部发票
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { GetStatementInfo, ConfirmInvoice } from "@/api/purchaseManage/invoiceFlow";
import {
  GetInvoicePackages,
  RejectedInvoice,
  GetPackagesBBSDetail,
  GetUploadInvoiceList,
  DownloadInvoiceAll,
} from "@/api/purchaseManage/markOut";
import { _getUserId } from "@/utils/storage";
export default {
  data() {
    return {
      bsid: "", // 运单开票编号
      status: "", // 运单状态
      tableData: [],
      loading: false,
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      flag: {
        confirmInvoiceDialog: false, // 确认收票弹窗
        rejectedInvoiceDialog: false, // 驳回重开弹窗
        invoiceDetailDialog: false, // 详情弹窗
        invoicePreviewDialog: false, // 发票包预览弹窗
        invoicePreviewLoading: false, // 获取发票包loading
      },
      settlementInfo: {},
      detailData: [],
      paginationDetail: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      invoicePics: [], // 发票数组
      currentPicIdx: "", // 当前预览的发票图片索引
      currentItem: {},
    };
  },
  methods: {
    // 获取列表
    getInvoicePackages() {
      this.loading = true;
      GetInvoicePackages({
        bsid: this.bsid,
      })
        .then((res) => {
          this.tableData = res.data || [];
          this.pagination.total = this.tableData.length;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
    },
    // 返回
    goBack() {
      if (this.$route.query.activeCmpt) {
        this.$router.push(
          `/purchaseManage/index/handlePurchaseOrder?activeCmpt=${this.$route.query.activeCmpt}`
        );
      } else {
        this.$router.push(`/purchaseManage/index/handlePurchaseOrder`);
      }
    },
    // 获取六要素等开票信息
    async getInvoiceTitleInfo() {
      await GetStatementInfo({
        BSID: this.bsid,
      }).then((res) => {
        this.settlementInfo = res.data || {};
      });
    },
    // 确认收票
    confirmInvoice() {
      const loading = this.$loading({
        lock: true,
        text: "确认收票中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ConfirmInvoice({
        bsid: this.bsid,
      })
        .then((res) => {
          this.$message.success("收票成功");
          this.$router.push(
            `/purchaseManage/index/handlePurchaseOrder?activeCmpt=BuyersComplete`
          );
          this.getInvoicePackages();
        })
        .finally(() => {
          this.flag.confirmInvoiceDialog = false;
          loading.close();
        });
    },
    toRejectedInvoice(row) {
      this.currentItem = row;
      this.flag.rejectedInvoiceDialog = true;
    },
    // 驳回重开
    rejectedInvoice() {
      const loading = this.$loading({
        lock: true,
        text: "驳回重开中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      RejectedInvoice({
        bsid: this.bsid,
        bpid: this.currentItem.BPID,
        applicantUserID: parseInt(_getUserId()) / 5,
      })
        .then((res) => {
          this.$message.success("驳回成功");
          this.$router.push(
            `/purchaseManage/index/handlePurchaseOrder?activeCmpt=BuyersComplete`
          );
          this.getInvoicePackages();
        })
        .finally(() => {
          this.flag.rejectedInvoiceDialog = false;
          loading.close();
        });
    },
    // 查看详情
    invoiceDetail(item) {
      this.currentItem = item;
      this.detailData = [];
      this.paginationDetail.page = 1;
      setTimeout(() => {
        this.flag.invoiceDetailDialog = true;
      }, 50);
      this.getPackagesBBSDetail(item.BPID);
    },
    // 获取详情列表
    async getPackagesBBSDetail(bpid) {
      this.loading = true;
      await GetPackagesBBSDetail({
        bpid,
        pageSize: this.paginationDetail.pagesize,
        pageIndex: this.paginationDetail.page,
        applicantUserID: parseInt(_getUserId()) / 5,
      })
        .then((res) => {
          this.detailData = res.data.DataList;
          this.paginationDetail.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCurrentDetailChange(e) {
      this.paginationDetail.page = e;
      this.getPackagesBBSDetail(this.currentItem.BPID);
    },
    // 获取发票包集合
    getUploadInvoiceList(row) {
      this.flag.invoicePreviewLoading = true;
      GetUploadInvoiceList({
        bpid: row.BPID,
      })
        .then((res) => {
          this.flag.invoicePreviewDialog = true;
          this.invoicePics = [];
          res.data.Normals.map((it) => {
            this.invoicePics.push(it.InvoiceImgUrl);
          });
          res.data.Specials.map((it) => {
            this.invoicePics.push(it.InvoiceImgUrl);
          });
          if (!this.invoicePics.length) {
            this.$message.warning("暂无可查看的发票！");
            return;
          } else {
            this.currentPicIdx = 0;
          }
        })
        .finally((res) => {
          this.flag.invoicePreviewLoading = false;
        });
    },
    // 上一张
    toPrevious() {
      if (this.currentPicIdx == 0) {
        this.$message.warning("已经是第一张了！");
        return;
      } else {
        this.currentPicIdx -= 1;
      }
    },
    // 下一张
    toNext() {
      if (this.currentPicIdx == this.invoicePics.length - 1) {
        this.$message.warning("已经是最后一张了！");
        return;
      } else {
        this.currentPicIdx += 1;
      }
    },
    // 下载全部发票
    downloadInvoiceAll() {
      const loading = this.$loading({
        lock: true,
        text: "发票下载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      DownloadInvoiceAll(
        {
          bsid: this.bsid,
        },
        {
          responseType: "blob",
        }
      )
        .catch((err) => {
          let { data } = err;
          let that = this;
          if (err.type == "application/json") {
            let reader = new FileReader();
            reader.readAsText(err);
            reader.onload = function (e) {
              const { msg } = JSON.parse(reader.result);
              that.$message({
                message: `下载失败 ${msg}`,
                type: "error",
              });
            };
            return;
          }

          const blob = new Blob([data]);
          let fileName = err.headers["content-disposition"].split(";")[1].split("=")[1]; //拆解获取文件名，
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
        })
        .finally(() => {
          loading.close();
        });
    },
  },
  created() {
    this.bsid = this.$route.query.bsid ? this.$route.query.bsid : "";
    this.status = this.$route.query.status ? this.$route.query.status : "";
    this.getInvoiceTitleInfo();
    this.getInvoicePackages();
  },
  computed: {
    // 列表分页
    currentTableData() {
      try {
        return this.tableData.slice(
          this.pagination.pagesize * (this.pagination.page - 1),
          this.pagination.pagesize * this.pagination.page
        );
      } catch (error) {}
    },
  },
};
</script>
<style lang="scss">
.invoicing-detail {
  .settleBox {
    margin: 10px 0px;
    padding: 10px 16px;
    background-color: #f0f0f0;
    color: #666;
  }

  .el-dialog {
    margin-top: 9vh !important;
  }

  .el-dialog__body {
    padding: 15px 20px;
  }
}

.imageError {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  span {
    color: red;
    font-size: 20px;
  }
}
</style>
